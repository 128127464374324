<template>
  <div>
    <div class="table-page-search-wrapper">
      <a-form layout="inline">
        <a-row :gutter="48">
          <a-col :md="8" :sm="24">
            <a-form-item label="时间">
              <a-range-picker @change="time" format="YYYY-MM-DD" style="width: 100%">
                <template slot="dateRender" slot-scope="current">
                  <div class="ant-calendar-date" :style="getCurrentStyle(current)" >
                    {{ current.date() }}
                  </div>
                </template>
              </a-range-picker>
            </a-form-item>
          </a-col>
          <a-col :md="2" :sm="24">
            <a-button type="primary" @click="searchBill">查询</a-button>
          </a-col>
        </a-row>
      </a-form>
    </div>
    <a-row>
      <a-col :span="11">
        <a-divider orientation="left">
          租金账单
        </a-divider>
        <s-table
          ref="table2"
          size="small"
          rowKey="id"
          :loading="loading"
          :columns="columns"
          :data="loadData2"
          :bordered="true"
          :showPagination="false"
        >
          <span slot="serial" slot-scope="text, record, index">
            {{ index + 1 }}
          </span>
          <div slot="money_render" slot-scope="text" style="text-align: right; min-width: 90px; max-width: 160px">
            {{ (text / 100).toFixed(2) | numberFormat }}
          </div>
          <div slot="percent" slot-scope="text">
            {{ (text * 100).toFixed(2) }}%
          </div>
        </s-table>
      </a-col>
      <a-col :span="1">
      </a-col>
      <a-col :span="12">
        <a-divider orientation="left">
          物业账单
        </a-divider>
        <s-table
          ref="table1"
          size="small"
          rowKey="id"
          :loading="loading"
          :columns="columns"
          :data="loadData"
          :bordered="true"
          :showPagination="false"
        >
          <span slot="serial" slot-scope="text, record, index">
            {{ index + 1 }}
          </span>
          <div slot="money_render" slot-scope="text" style="text-align: right; min-width: 90px; max-width: 120px">
            {{ (text / 100).toFixed(2) | numberFormat }}
          </div>
          <div slot="percent" slot-scope="text">
            {{ (text * 100).toFixed(2) }}%
          </div>
        </s-table>
      </a-col>
    </a-row>
  </div>
</template>

<script>
import { STable } from '@/components'
import { bill_statistics_list } from '@/api/rent_bill'

export default {
  name: 'TableList',
  components: {
    STable
  },
  data () {
    return {
      dataSource: [],
      loading: false,
      // 高级搜索 展开/关闭
      advanced: false,
      // 查询参数
      queryParam: {},
      // 表头
      columns: [
        {
          title: '序号',
          scopedSlots: { customRender: 'serial' },
          width: 50
        },
        {
          title: '企业名称',
          dataIndex: 'corporation_name',
          ellipsis: true
        },
        {
          title: '应付(元)',
          dataIndex: 'total_payment__sum',
          scopedSlots: { customRender: 'money_render' },
          align: 'right',
          width: 120,
          ellipsis: true
        },
        {
          title: '实付(元)',
          align: 'right',
          width: 120,
          dataIndex: 'actual_payment__sum',
          scopedSlots: { customRender: 'money_render' },
          ellipsis: true
        },
        {
          title: '差额(元)',
          dataIndex: 'diff',
          scopedSlots: { customRender: 'money_render' },
          ellipsis: true,
          align: 'right',
          width: 120
        },
        {
          title: '实付比例',
          dataIndex: 'payment_percent',
          scopedSlots: { customRender: 'percent' },
          ellipsis: true,
          width: 100,
          align: 'center'
        }
      ],
      // 加载数据方法 必须为 Promise 对象
      loadData: parameter => {
        console.log('loadData.parameter', parameter)
          return bill_statistics_list(Object.assign(parameter, this.queryParam))
            .then(res => {
              const data = { entries: [] }
              if (res.code === 2000) {
                return data
              } else {
                data.entries.push(...res.data.property_bill_data.property_bill_percent)
                  // data.entries.push(...res.data.property_bill_data.property_bill_top)
                  // data.entries.push(...res.data.rent_bill_data.rent_bill_percent)
                // data.entries.push(...res.data.rent_bill_data.rent_bill_top)
                data.entries = data.entries.map((item, index, array) => {
                  item.data_id = item.id
                  item.id = index + 1
                  return item
                })
                return data
              }
            })
      },
      loadData2: parameter => {
        console.log('loadData.parameter', parameter)
          return bill_statistics_list(Object.assign(parameter, this.queryParam))
            .then(res => {
              const data = { entries: [] }
              if (res.code === 2000) {
                return data
              } else {
                // data.entries.push(...res.data.property_bill_data.property_bill_percent)
                  // data.entries.push(...res.data.property_bill_data.property_bill_top)
                data.entries.push(...res.data.rent_bill_data.rent_bill_percent)
                // data.entries.push(...res.data.rent_bill_data.rent_bill_top)
                data.entries = data.entries.map((item, index, array) => {
                  item.data_id = item.id
                  item.id = index + 1
                  return item
                })
                return data
              }
            })
      },
      selectedRowKeys: [],
      selectedRows: [],

      // custom table alert & rowSelection
      options: {
        alert: { show: true, clear: () => { this.selectedRowKeys = [] } },
        rowSelection: {
          selectedRowKeys: this.selectedRowKeys,
          onChange: this.onSelectChange
        }
      },
      optionAlertShow: false
    }
  },
  created () {
    this.tableOption()
    this.queryParam.start_date = '1970-01-01'
    this.queryParam.end_date = '1970-01-01'
  },
  methods: {
    searchBill () {
      this.$refs.table1.refresh(true)
      this.$refs.table2.refresh(true)
    },
    onChange (value) {
      console.log(value)
    },
    time (dates, dateStrings) {
      console.log(dateStrings, '时间')
      if (dateStrings[0] === '') {
        this.queryParam.start_date = '1970-01-01'
        this.queryParam.end_date = '1970-01-01'
        return
      }
      this.queryParam.start_date = dateStrings[0]
      this.queryParam.end_date = dateStrings[1]
    },
    getCurrentStyle (current, today) {
      const style = {}
      if (current.date() === 1) {
        style.border = '1px solid #1890ff'
        style.borderRadius = '50%'
      }
      return style
    },
    tableOption () {
      if (!this.optionAlertShow) {
        this.options = {
          alert: { show: true, clear: () => { this.selectedRowKeys = [] } },
          rowSelection: {
            selectedRowKeys: this.selectedRowKeys,
            onChange: this.onSelectChange
          }
        }
        this.optionAlertShow = true
      } else {
        this.options = {
          alert: false,
          rowSelection: null
        }
        this.optionAlertShow = false
      }
    },
    onSelectChange (selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
    }
  }
}
</script>
